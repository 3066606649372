.wrapper {
    height: 365px;
    padding: 0 24px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 12px;
    border: 0;
    background-color: white;
    box-shadow: 0 16px 36px rgba(0, 0, 0, 0.2);
}
